<script lang="ts">
  import SecretSaved from "$components/secret/SecretSaved.svelte";
  import SecretUpdateForm from "$components/secret/SecretUpdateForm.svelte";

  export let secretId: string;
  export let serviceId: string;
  let saved = false;
</script>

<main class="section secret {serviceId}">
  {#if saved}
    <SecretSaved service={serviceId} />
  {:else}
    <SecretUpdateForm
      service={serviceId}
      id={secretId}
      on:submitted={(e) => (saved = true)}
    />
  {/if}
  <aside />
</main>
