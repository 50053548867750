<script lang="ts">
  export let name: string | undefined | null = null;
  export let type: "button" | "submit" | "reset" = "button";
  export let value: string | undefined | null = null;
  export let text: string = "";
  export let disabled = false;
  let className: string = "";
  export { className as class };
</script>

<button {disabled} {type} {name} {value} class={className || ""}>
  {text}
  <slot />
</button>
