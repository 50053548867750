import App from "./App.svelte";
import "./app.scss";
import { end, router, route } from "$utils/router";
import { ensureLoggedIn } from "$utils/auth";
import { fetchCreateID } from "$utils/api";
import type { Routing } from "$utils/router";

//ensureLoggedIn(true);

function auth(ctx: Routing, next: Function) {
  ensureLoggedIn(true);
  if (next) next();
}

new App({
  target: document.body,
});

router("/:service/secret/new", function (ctx: Routing) {
  fetchCreateID().then((id) => {
    route(`/${ctx.params.service}/secret/${id}`, false);
  });
});

router("/:service/secret/:secret", end);
router("/secret/:secret", end);

//router("/:service/:action/:state", auth, end);
router("/:service/:action", auth, end);
router("/:service", auth, end);

router.start();
