<script lang="ts">
  import { createEventDispatcher } from "svelte";

  export let id: string | null | undefined = null;
  export let type = "text";
  export let name: string | null | undefined = null;
  export let title: string | null | undefined = null;
  export let placeholder: string | null | undefined = null;
  export let required = false;
  export let value: string = "";
  export let minlength: string | null | undefined = null;
  export let maxlength: string | null | undefined = null;
  export let min: string | number | null | undefined = null;
  export let max: string | number | null | undefined = null;
  export let autocomplete: string | null | undefined = null;
  export let multiple = false;
  export let spellcheck: boolean | null | undefined = null;

  const events = createEventDispatcher<{
    change: {
      name: string;
      value: string;
    };
    input: {
      name: string;
      value: string;
    };
    blur: {
      name: string;
      value: string;
    };
  }>();

  function change(name: string, newvalue: string) {
    if (newvalue === value) return;
    value = newvalue;
    if (name)
      events("change", {
        name,
        value,
      });
  }
  function parseIntOrNull(input: string | number | null | undefined) {
    if (null == input) return null;
    const parsed = parseInt(input + "");
    if (isNaN(parsed)) return null;
    return parsed;
  }
</script>

<input
  {type}
  {id}
  {name}
  {required}
  {placeholder}
  {autocomplete}
  {spellcheck}
  {multiple}
  {title}
  value={value || ""}
  minlength={parseIntOrNull(minlength)}
  maxlength={parseIntOrNull(maxlength)}
  {min}
  {max}
  on:change={({ currentTarget: target }) => change(target.name, target.value)}
  on:input={({ currentTarget: target }) => change(target.name, target.value)}
  on:blur={({ currentTarget: target }) => change(target.name, target.value)}
/>
