export function toObject(str) {
  const query = new URLSearchParams(str);
  return [...query.keys()].reduce((result, k) => {
    var values = query.getAll(k);
    if (values?.length === 1) {
      result[k] = values[0];
    } else if (values?.length) {
      result[k] = values;
    }
    return result;
  }, {});
}
