import { base, responseJson, viewpoint } from "$utils/api";

export async function fetchUpdateSecret(
  id: string,
  value: string
): Promise<any> {
  var data = new FormData();
  data.append("id", id);
  data.append("value", value);

  const res = await fetch(`${base()}/secrets/${id}?viewpoint=${viewpoint()}`, {
    method: "PUT",
    body: data,
  });

  const json = await responseJson(res);

  return json;
}
