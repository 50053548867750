import { writable, derived, readable } from "svelte/store";
import { items } from "@parkingboss/svelte-utils";
import { param } from "./params";
import {
  fetchAndStoreProperty,
  fetchConnectService,
  fetchProperty,
  fetchServices,
} from "./api";

export { items as state };

// we have to take the state param back from stripe
export const propertyId = param("state", true);
export const serviceId = param("service");
export const secretId = param("secret");
export const code = param("code", true);
export const action = param("action", true);
// export const services = readable(null, async function start(set) {
//   var json = await fetchServices();
//   set(json.services);
// });
export const service = derived(
  [serviceId, propertyId, code],
  async function ([$id, $propertyId, $code], set) {
    if (!$id) return set(null);
    if (!$propertyId) return set(null);

    var json = $code
      ? await fetchConnectService(
          $propertyId,
          $id,
          $code,
          location.origin + location.pathname
        )
      : await fetchServices($propertyId, $id);

    var service = getServiceFromResult(json, $id, $propertyId);

    console.log("service=", service, $propertyId);

    set(service);
  }
);

function getServiceFromResult(json, id, subject) {
  var service =
    json[id] ||
    json.services?.items[id] ||
    Object.values(json.services?.items || {}).find((svc) => svc.service == id);
  if (service && json.services?.["for"]?.[subject]?.items[service.id])
    service.subject = subject;

  if (service) {
    var url = new URL(service.oauth.authorize);
    if (service.client) url.searchParams.set("client_id", service.client);
    if (service.oauth.scope) url.searchParams.set("scope", service.oauth.scope);
    url.searchParams.set("response_type", "code");
    url.searchParams.set("redirect_uri", "");
    service.url = `${url.toString()}`;
  }
  return service;
}

export const property = derived(propertyId, async function ($propertyId, set) {
  if (!$propertyId) return set(null);

  var json = await fetchProperty($propertyId);

  set(json.items[$propertyId]);
});

// set property
// export const property = derived(
//   [propertyId, items],
//   ([$id, $state]) => $id && $state[$id]
// );

// propertyId.subscribe(($propertyId) => {
//   if (!$propertyId) return;
//   fetchAndStoreProperty($propertyId);
// });
