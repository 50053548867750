<script lang="ts">
  import { createEventDispatcher } from "svelte";

  let className: string = "";
  export { className as class };
  export let element: HTMLFormElement;

  var events = createEventDispatcher<{
    submit: HTMLFormElement;
  }>();
</script>

<form
  bind:this={element}
  class={className || ""}
  on:submit|preventDefault={(e) => events("submit", e.currentTarget)}
>
  <slot />
</form>
