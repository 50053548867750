//import { qs } from "@parkingboss/utils";
import { params } from "./params";
import page from "page";
import { toObject as qs } from "$utils/querystring";
import type { Context } from "page";

// bootstrap the previously loaded page
export const router = page;
export type Routing = Context;

export default router;

//qs mechanics
router("*", function (ctx: Context, next: Function) {
  // don't run auth, we're running out of band

  ctx.query = Object.assign(qs(ctx.querystring), qs(ctx.hash));
  next();
});

// qs mechanics
// page("*", function (ctx, next) {
//   // don't run auth, we're running out of band
//   const query = new URLSearchParams(ctx.querystring);
//   ctx.query = [...query.keys()].reduce((result, k) => {
//     var values = query.getAll(k);
//     if (values?.length === 1) {
//       result[k] = values[0];
//     } else if (values?.length) {
//       result[k] = values;
//     }
//     return result;
//   }, ctx.query ?? {});
//   //console.log(ctx.query);

//   next();
// });

// // hash mechanics
// page("*", function (ctx, next) {
//   // don't run auth, we're running out of band
//   const query = new URLSearchParams(ctx.hash);
//   ctx.query = [...query.keys()].reduce((result, k) => {
//     var values = query.getAll(k);
//     if (values?.length === 1) {
//       result[k] = values[0];
//     } else if (values?.length) {
//       result[k] = values;
//     }
//     return result;
//   }, ctx.query ?? {});
//   //console.log(ctx.query);

//   next();
// });

export function state(ctx: Context, next?: Function) {
  params.set(
    Object.assign(
      {},
      ctx.params,
      ctx.query,
      qs(location.hash && location.hash.substring(1))
    )
  );
  if (!!next) next();
}

export function route(url: string, history = true) {
  if (!history) router.replace(url);
  router(url);
}

export function end(ctx: Context) {
  state(ctx);
}

export function query(key, value, path) {
  const qs = new URLSearchParams(window.location.search);

  if (typeof key == "object") {
    for (const [k, v] of Object.entries(key)) {
      if (v) qs.set(k, v);
      else qs.delete(k);
    }
  } else {
    if (value) qs.set(key, value);
    else qs.delete(key);
  }

  let q = qs.toString();
  if (q) q = "?" + q;
  route((path || window.location.pathname) + q);
}
