import { derived, writable } from "svelte/store";
//import { writable } from "@parkingboss/svelte-storage";

export const params = writable({});

function paramStore(selector, onlyUpdateForRealValues = false, defaultValue = null) {

    var value = null;
    return derived(params, (params, set) => {
        if(!params) return;
        var newValue = selector(params);
        if(!newValue && null != defaultValue) newValue = defaultValue;
        if(!newValue && !value) return;
        if(!newValue && onlyUpdateForRealValues) return;
        //console.log("setting new paramstore value", newValue);
        if(newValue != value) set(value = newValue);
    });

}

const stores = {};

export const param = (property, onlyUpdateWhenSet = false, defaultValue = null) => stores[property] || (stores[property] = paramStore($params => $params[property], onlyUpdateWhenSet, defaultValue));