<script lang="ts">
  import Button from "$components/form/Button.svelte";
  import Form from "$components/form/Form.svelte";
  import TextField from "$components/form/TextField.svelte";
  import ServiceLogo from "$components/service/ServiceLogo.svelte";
  import { createEventDispatcher } from "svelte";
  import { fetchUpdateSecret } from "./api";
  export let service: string;
  export let id: string;
  let form: HTMLFormElement;
  let values: Record<string, any> = {};

  var events = createEventDispatcher<{
    submitted: FormData;
    change: Record<string, any>;
  }>();

  let submitting = false;
  let submittable = false;

  async function submit(e: CustomEvent<HTMLFormElement>) {
    const form = e.detail;

    if (submitting || !submittable) return;

    submitting = true;

    const data = new FormData(form);

    await fetchUpdateSecret(id, values.value);

    events("submitted", data);

    submitting = false;
  }
  function change(name: string, value: any) {
    //console.log("change", name, value);
    values[name] = value;
    events("change", values);
  }

  $: submittable = !!values.value;
</script>

<Form bind:element={form} class="secret" on:submit={submit}>
  <ul>
    <li>
      <figure class="service record value">
        <figcaption>Service</figcaption>
        <ServiceLogo {service} />
      </figure>
    </li>
    <li>
      <TextField
        spellcheck={false}
        autocomplete="off"
        label="Password"
        required
        name="value"
        on:change={(e) => change(e.detail.name, e.detail.value)}
      />
    </li>
  </ul>
  <footer>
    <Button disabled={submitting || !submittable} type="submit"
      >{submitting ? "Saving…" : "Save Password"}</Button
    >
  </footer>
</Form>
