import { updateItems } from "@parkingboss/svelte-utils";
import { toZoneISOString } from "./datetime";
import { api, auth } from "./auth";

export { api };

// update auth header as auth changes
export let authHeader = "";
auth.subscribe(
  ($auth) => (authHeader = $auth && `${$auth.type} ${$auth.token}`)
);

export function viewpoint() {
  return toZoneISOString(new Date());
}

export function base() {
  return api.settings.apiBase;
}

export function authorize() {
  return "&Authorization=" + (authHeader || "");
}

export async function responseJson(response: Response): Promise<any> {
  if (!response) return {};
  return response
    .text()
    .then(function (text) {
      if (!text)
        return {
          status: response.status,
        };

      return Promise.resolve(text)
        .then(JSON.parse)
        .catch(function (error) {
          return {
            status: response.status,
            message: text,
          };
        });
    })
    .catch(function (error) {
      return {
        status: response.status,
      };
    });
}

export async function fetchAndStoreProperty(property) {
  const res = await fetch(
    `${base()}/properties?viewpoint=${viewpoint()}&property=${property}`
  );
  const json = await res.json();

  for (const item of Object.values(json.items)) {
    json.items[item.uuid.split("-").join("")] = item;
  }

  updateItems(json);

  return json;
}

export async function fetchProperty(property) {
  const res = await fetch(
    `${base()}/properties?viewpoint=${viewpoint()}&property=${property}`
  );
  const json = await res.json();

  for (const item of Object.values(json.items)) {
    json.items[item.uuid.split("-").join("")] = item;
  }

  //updateItems(json);

  return json;
}

export async function fetchServices(scope, service) {
  const res = await fetch(
    `${base()}/services?service=${service}&for=${
      scope || ""
    }&viewpoint=${viewpoint()}&${authorize()}`,
    {
      //method: !!code ? "post" : "get"
    }
  );

  const json = await res.json();

  return json;
}

export async function fetchConnectService(scope, service, code, redirect_uri) {
  const res = await fetch(
    `${base()}/services/${service}/connect?viewpoint=${viewpoint()}&state=${
      scope.id || scope
    }&code=${code || ""}&redirect_uri=${redirect_uri}${authorize()}`,
    {
      //method: !!code ? "post" : "get"
    }
  );

  const json = await res.json();

  return json;
}

export async function fetchCreateID(): Promise<string> {
  const res = await fetch(`${base()}/id?viewpoint=${viewpoint()}`, {
    //method: !!code ? "post" : "get"
  });

  const json = await res.json();

  return Object.values(json.identification.items)[0].id as string;
}

function statify(data, state) {
  state = state || data;

  for (const [key, value] of Object.entries(data)) {
    if (!value.items) continue;

    state.items[key] = value;

    for (const [id, v] of Object.entries(value.items)) {
      if (typeof v !== "string") continue;
      value.items[id] = data.items[v] || data.items[id] || v;
    }
  }
}

export function resolveAddress(item, items) {
  if (!item) return item;
  item.address = items[item.address] || item.address;
  return item;
}

export function resolveProperty(item, items) {
  if (!item) return item;
  if (typeof item === "string") item = items[item];
  if (item) item.stripe = items.stripe;
  return resolveAddress(item, items);
}
