<script lang="ts">
  import ServiceLogo from "$components/service/ServiceLogo.svelte";

  export let service: any;
</script>

<article class="secret details {service}">
  <dl>
    <dt>Service</dt>
    <dd><ServiceLogo {service} /></dd>
    <dt>Password</dt>
    <dd>Saved</dd>
  </dl>
</article>
