<script lang="ts">
  export let service: any;

  $: name = typeof service == "string" ? service : service.name;
  $: id = typeof service == "string" ? service : service.service;
</script>

<figure class="logo">
  <img alt="{name} logo" class="service logo {id}" src="/{id}.svg" />
</figure>
