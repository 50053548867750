<script>
  import {
    property,
    code,
    service,
    action,
    secretId,
    serviceId,
  } from "./util/stores";
  import SecretSection from "$sections/SecretSection.svelte";
  import ServiceConnectSection from "$sections/ServiceConnectSection.svelte";

  $: if ("authorize" == $action && $service && $property && !$code) {
    window.location.href = `${$service.url}${
      location.origin + location.pathname
    }&state=${$property.id}`;
  }
</script>

{#if $secretId}
 <SecretSection serviceId={$serviceId} secretId={$secretId} />
{:else}
  <ServiceConnectSection
    service={$service}
    property={$property}
    code={$code}
    action={$action} />
{/if}
<!-- <<nav>
  ul>
    <li>
      Questions? Visit our <a href="https://help.communityboss.app"
        >help center</a
      >
    </li>
    <li>
      Check our <a href="https://status.parkingboss.com">system status</a> and
      <a href="https://updates.parkingboss.com">latest updates</a>
    </li>
    <li>
      Our <a href="https://parkingboss.com/terms">terms of use</a> &amp;
      <a href="https://parkingboss.com/privacy">privacy policy</a>
    </li>
  </ul>
</nav> -->
<footer>
  <p>Copyright &copy; Luminous. All rights reserved.</p>
</footer>
