<script lang="ts">
  import ServiceLogo from "$components/service/ServiceLogo.svelte";

  export let service;
  export let property;
  export let code: string;
  export let action: string;
</script>

<main class="section service {service?.service}">
  <header>
    <h1>{property?.name || "Loading…"}</h1>
  </header>

  {#if service && property}
    <article class="service {service?.service} details">
      <dl>
        <!-- <dt>Property</dt>
        <dd>{property?.name}</dd> -->
        <dt>Service</dt>
        <dd><ServiceLogo {service} /></dd>
        {#if service.subject || service.account}
          <dt>Status</dt>
          <dd>Connected</dd>
        {/if}
      </dl>
      {#if service.subject || service.account}
        <!-- <figure class="success">Connected to {service.name}</figure> -->
      {:else if code || "authorize" == action}
        <!-- <figure class="working">Connecting to {service.name}</figure> -->
      {:else}
        <a href="/{service.service}/authorize?state={property.id}">Connect</a>
      {/if}
    </article>
  {:else if property}
    <figure class="working">Loading</figure>
  {/if}
  <aside style="background-image:url(/communityboss-{service?.service}.svg);" />
</main>
